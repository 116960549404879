<template>

  <PDialog 
    :title="$tk('SendDraftsDialog.SendDrafts')" 
    classes="left-0 md:left-auto md:w-2/5" 
    @close="$emit('close')"
  >

    <PDialogBody>

      <div class="flex-1 overflow-y-auto">
        
        <p-loader v-if="isLoading" />
        
        <template v-else>
          
          <table class="data-table" v-if="orders.length > 0">
            <thead>
              <tr>
                <th class="text-left" v-html="$tk('Common.Order.OrderNo')"></th>
                <th class="text-left" v-html="$tk('Common.Order.To')"></th>
                <th class="text-left" v-html="$tk('Common.Order.ReceiversRef')"></th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(order, index) in orders" 
                :key="index"                
              >                
                <td>{{ order.id }}</td>
                <td>{{ order.locationNameTo }}</td>
                <td>{{ order.receiversRef }}</td>
              </tr>
            </tbody>
          </table>
          
        </template>
      </div>

    </PDialogBody>

    <PDialogActions>

      <PButton 
        color="secondary" 
        @click="onCancel"
        :disabled="isSubmitting"        
      >
        <span v-html="$tk('Common.Actions.Cancel')"></span>
      </PButton>

      <PButton 
        class="ml-2"
        color="primary" 
        @click="onSubmit"
        :disabled="!canSubmit"
        :loading="isSubmitting"        
      >
        <span v-html="$tk('Common.Actions.Send')"></span>
      </PButton>

    </PDialogActions>

  </PDialog>

</template>

<script>

import http from "@/http"
import api from "@/api/orders"


import { 
  mapGetters 
} from "vuex"

import {
  forEach
} from "lodash"

export default {

  data () {
    return {
      orders: [],
      isLoading: false,
      isSubmitting: false
    }
  },

  computed: {

    ...mapGetters([
      "user",
      "location"
    ]),

    canSubmit () {
      return this.orders.length > 0
    }

  },

  methods: {

    async init () {

      this.isLoading = true

      const params = {
        transactionType: "GL,OO",
        locationIdFrom: this.location.id
      }

      const filters = {
        statuses: [
          { id: 0, value: true }
        ]
      }

      try {

        this.orders = await api.getOrders(
          "orders",
          params,          
          "id", 
          false, 
          filters,
          9999
        )

      } catch (error) {

        this.$store.dispatch("notify", { 
          type: "negative", 
          text: error.reason
        })
        
      }      

      this.isLoading = false
    },

    async onSubmit () {

      let arr = []

      forEach(this.orders, order => {
        arr.push(
          http.post("OrderStatus", {
            id: order.id,
            transactionStatus: 4
          })
        )
      })
      
      try {
        
        await Promise.all(arr)
        
        this.$store.dispatch("notify", {
          type: "positive",
          text: this.$t("SendDraftsDialog.DraftsWasSent", { count: arr.length })
        })

        this.$emit("close")

      } catch (error) {

        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })

        this.init()

      }

    },

    onCancel () {
      this.$emit("close")
    }

  },

  async created () {
    await this.init ()    
  }

}

</script>

<style scoped>

th {
  @apply p-4 leading-4 bg-gradient-to-b from-gray-500 to-gray-600;
}

tr {
  background-color: theme('colors.gray.50');
}

tr:nth-child(odd) {
  background-color: theme('colors.gray.100')
}

tr:hover {
  background-color: theme('colors.orange.200');
}

td {
  @apply px-4 py-2;
  border-bottom: 1px solid theme('colors.gray.300');
  border-right: 1px solid theme('colors.gray.300');
}

tr.has-errors {
  background-color: theme('colors.red.200');
  color: theme('colors.red.700');
}

tr.has-errors td {  
  border-bottom: 1px solid theme('colors.red.300');
  border-right: 1px solid theme('colors.red.300');
}

tr.update-ok {
  background-color: theme('colors.green.200');
  color: theme('colors.green.700');
}

tr.update-ok td {  
  border-bottom: 1px solid theme('colors.green.300');
  border-right: 1px solid theme('colors.green.300');
}



</style>